<template>
  <section
    id="core-competencies-new"
    style="background-color:#ebedee;"
  >
    <div
      class="pt-10 pb-15"
    >
      <base-subtitle
        title="Nitrile Glove Manufacturing"
        space="1"
        tag="h2"
        size="text-h5"
        align="center"
        class="text-uppercase"
      />

      <base-subheading
        align="center"
        title="Core Competencies"
        class="text-uppercase"
        space="2"
      />

      <base-divider
        color="primary"
        align="center"
      />
    </div>
    <base-left-photo
      :src="require('@/assets/howell_1.jpg')"
      subheading="DESIGN"
      html="<ul class='text-h6'>
      <li>Glove Production Line</li>
      <li>Heating System</li>
      <li>Water Purification System</li>
      <li>Wastewater Treatment System</li>
      <li>Automation System</li></ul>"
      color="black--text"
    >
    </base-left-photo>
    <base-right-photo
      :src="require('@/assets/howell_2.jpg')"
      subheading="FABRICATION"
      html="<ul class='text-h6'><li>Tanks</li>
      <li>Chlorinator</li>
      <li>Heating System</li>
      <li>Machine and Production Line Structure</li>
      <li>Water Purification System</li></ul>"
      color="black--text"
    >
    </base-right-photo>
    <base-left-photo
      :src="require('@/assets/howell_3.jpg')"
      subheading="INSTALLATION"
      html="<ul class='text-h6'><li>Production Line</li>
      <li>Utilities</li></ul>"
      color="black--text"
    >
    </base-left-photo>
    <base-right-photo
      :src="require('@/assets/howell_4.jpg')"
      subheading="COMMISSIONING"
      html="<ul class='text-h6'><li>Line Startup</li>
      <li>Debugging</li>
      <li>Optimization</li></ul>"
      color="black--text"
    >
    </base-right-photo>
    <base-left-photo
      :src="require('@/assets/howell_5.jpeg')"
      subheading="PRODUCTION"
      html="<ul class='text-h6'><li>Quality Assurance</li>
      <li>Process Technology</li>
      <li>ASTM and EN Product compliance</li></ul>"
      color="black--text"
    >
    </base-left-photo>
    <base-right-photo
      :src="require('@/assets/howell_6.jpeg')"
      subheading="TRADING OF ALL TYPES OF RUBBER GLOVES"
      html="<ul class='text-h6'><li>Nitrile gloves</li>
      <li>Natural rubber gloves</li></ul>"
      color="black--text"
    >
    </base-right-photo>
  </section>
</template>

<script>
  export default {
    name: 'SectionCoreCompetenciesNew',
  }
</script>
